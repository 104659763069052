import React, { useState, useEffect, useRef } from 'react'
import sha1 from 'sha1'

const psw = '8cb2237d0679ca88db6464eac60da96345513964'

const Aic3D = () => {

    const [isOpen3dView, setIsOpen3dView] = useState(true)
    const [isPasswordCorrect, setIsPasswordCorrect] = useState(true)
    const [inputData, setInputData] = useState('')

    const compareDataFromInputTokey = () => {
        var hash = sha1(inputData)
        hash === psw ? setIsOpen3dView(true) : setIsPasswordCorrect(false)
    }

    const matterportSDKListener = async (mpSdk, data) => {
        const resp = await mpSdk.Mattertag.getData(data)
        console.log("getData, data: ", resp)
    }

    useEffect(() => {

        const iframe = document.getElementById('showcase-iframe');

        (async () => {
            // connect to sdk
            try {
                const mpSdk = await window.MP_SDK.connect(
                    iframe, // Obtained earlier
                    '5um7wtbf78mf6ut7dh7ftenuc', // Your SDK key
                    '' // Unused but needs to be a valid string
                );
                console.log('mpSdk: ', mpSdk)

                // Start listening to the event.
                mpSdk.on(mpSdk.Mattertag.Event.CLICK, data => matterportSDKListener(mpSdk, data))

                return () => {
                    // Stop listening to the event.
                    mpSdk.off(mpSdk.Mattertag.Event.CLICK, data => matterportSDKListener(mpSdk, data))
                }
            } catch (e) {
                console.error(e);
            }
        })()

    }, [])



    return (
        <div className='fullScreanSize'>
            {isOpen3dView
                ? <iframe
                    // className=' img'
                    width="100%"
                    height="100%"
                    // src="https://my.matterport.com/show/?m=i7kmj5kB9oA&play=1"
                    src="https://my.matterport.com/show/?m=i7kmj5kB9oA&play=1"
                    // src="https://my.matterport.com/show/?m=gtPinY7RrX1&play=1"
                    // src="https://my.matterport.com/models/gtPinY7RrX1"
                    // src="https://my.matterport.com/show/?m=Re2dgaSVCRj&utm_source=sdkdebug"
                    frameBorder="0"
                    allow="fullscreen; vr"
                    id="showcase-iframe">
                </iframe>
                : <div className="aic3D_password">
                    <div className="aic3D_password_container">
                        <input
                            placeholder="password"
                            style={!isPasswordCorrect ? { borderColor: 'red' } : {}}
                            type="password"
                            value={inputData}
                            className="aic3D_password_input"
                            onChange={e => {
                                setInputData(e.target.value)
                                setIsPasswordCorrect(true)
                            }}
                        />
                        <button className="btn_dark_small" onClick={() => compareDataFromInputTokey()}>OK</button>

                    </div>
                </div>
            }
        </div>
    )
}

export default Aic3D
