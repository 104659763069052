import React from 'react'

// translation
import { translation } from '../utils/translation'

// icons nav
import { ReactComponent as HomeIcon } from '../assets/icons/home.svg'
import { ReactComponent as HomeIconFilled } from '../assets/icons/home_filled.svg'
import { ReactComponent as CameraIcon } from '../assets/icons/camera.svg'
import { ReactComponent as CameraIconFilled } from '../assets/icons/camera_filled.svg'
import { ReactComponent as NftIcon } from '../assets/icons/NFT.svg'
import { ReactComponent as NftIconFilled } from '../assets/icons/NFT_filled.svg'
import { ReactComponent as WalletIcon } from '../assets/icons/wallet.svg'
import { ReactComponent as WalletIconFilled } from '../assets/icons/wallet_filled.svg'
import { ReactComponent as LifebuoyIcon } from '../assets/icons/lifebuoy.svg'
import { ReactComponent as LifebuoyIconFilled } from '../assets/icons/lifebuoy_filled.svg'
import { ReactComponent as Model3D } from '../assets/icons/model3d.svg'
import { ReactComponent as Model3DFilled } from '../assets/icons/model3d.svg'

const Nav = ({ screenNumber, setSreenNumber }) => {
    return (
        <nav className='nav'>
            <hr className='hr' />
            <div className='nav__container'>

                <p className={`nav__item ${screenNumber === 1 && 'nav__item_active'}`} onClick={() => setSreenNumber(1)}>
                    {screenNumber === 1 ? <HomeIconFilled className='nav__icon' /> : <HomeIcon className='nav__icon' />}{translation('Dom')}</p>

                <p className={`nav__item ${screenNumber === 2 && 'nav__item_active'}`} onClick={() => setSreenNumber(2)}>
                    {screenNumber === 2 ? <NftIconFilled className='nav__icon' /> : <NftIcon className='nav__icon' />}{translation('NFT')}</p>

                <p className={`nav__item ${screenNumber === 3 && 'nav__item_active'}`} onClick={() => setSreenNumber(3)}>
                    {screenNumber === 3 ? <WalletIconFilled className='nav__icon' /> : <WalletIcon className='nav__icon' />}{translation('Portfel')}{!window.ethereum && <span className='dot'></span>}</p>

                <p className={`nav__item ${screenNumber === 4 && 'nav__item_active'}`} onClick={() => setSreenNumber(4)}>
                    {screenNumber === 4 ? <CameraIconFilled className='nav__icon' /> : <CameraIcon className='nav__icon' />}{translation('Skaner')}</p>

                <p className={`nav__item ${screenNumber === 7 && 'nav__item_active'}`} onClick={() => setSreenNumber(7)}>
                    {screenNumber === 7 ? <Model3DFilled className='nav__icon' /> : <Model3D className='nav__icon' />}AIC</p>

                <p className={`nav__item ${screenNumber === 5 && 'nav__item_active'}`} onClick={() => setSreenNumber(5)}>
                    {screenNumber === 5 ? <LifebuoyIconFilled className='nav__icon' /> : <LifebuoyIcon className='nav__icon' />}{translation('Pomoc')}</p>

            </div>
        </nav>
    )
}

export default Nav
